import styled from 'styled-components'

type State = 'playing' | 'paused'

interface Props {
  diameter?: number
  state?: State
}
interface FinalProps extends Props {
  diameter: number
  state: State
  iconSize: number
  borderWidth: string
  borderStyle: string
  marginLeft: number
}

const PlayPauseButton = styled.button.attrs<Props, FinalProps>(props => {
  const { diameter = 30, state = 'paused' } = props
  const iconSize = diameter * 0.4
  const isPlaying = state === 'playing'
  return {
    diameter,
    state,
    iconSize,
    borderWidth: isPlaying
      ? `0px 0 0px ${iconSize}px`
      : `${iconSize / 2}px 0 ${iconSize / 2}px ${iconSize}px`,
    borderStyle: isPlaying ? 'double' : 'solid',
    marginLeft: isPlaying ? 0 : 0.16 * iconSize,
  }
})`
  -js-display: flex;
  display: flex;
  border: 0;
  overflow: visible;
  width: ${props => props.diameter}px;
  height: ${props => props.diameter}px;
  border-radius: 50%;
  align-items: center;
  background: ${props => props.theme.primary};
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.25s linear;
  &:before {
    content: '';
    width: ${props => props.iconSize}px;
    height: ${props => props.iconSize}px;
    margin-left: ${props => props.marginLeft}px;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    border-style: ${props => props.borderStyle};
    border-width: ${props => props.borderWidth};
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px rgb(183, 204, 241);
  }
  &:hover {
    box-shadow: ${props => (props.theme as any).ac.shadows.short};
    &:focus {
      box-shadow: 0 0 0 4px rgb(183, 204, 241),
        ${props => (props.theme as any).ac.shadows.short};
    }
  }
`

export default PlayPauseButton
