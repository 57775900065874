import React from 'react'
import WaveSurfer from 'wavesurfer.js'
import styled, { ThemeContext } from 'styled-components'
import Props from './Props'
import { CircleLoader } from '@aristech/components'
import PlayPauseBtn from '../PlayPauseButton'
import * as moment from 'moment'
import { useIntl } from 'gatsby-plugin-intl'

// eslint-disable-next-line
const CursorPlugin = require('wavesurfer.js/dist/plugin/wavesurfer.cursor.js')

// Just a cached support list
const supportCache: { [type: string]: boolean } = {}
const supportsType = (type: string) => {
  // Check if this type was checked before
  const cachedKeys = Object.keys(supportCache)
  if (cachedKeys.indexOf(type) !== -1) {
    return supportCache[type]
  }
  const audio = document.createElement('audio')
  const supported = !!(
    audio.canPlayType && audio.canPlayType(type).replace(/no/, '')
  )
  // Add the support info to the cache
  supportCache[type] = supported
  return supported
}

const formatDuration = (duration: moment.Duration) => {
  let str = ''
  const h = duration.hours()
  if (h > 0) {
    const hh = h < 10 ? `0${h}` : `${h}`
    str += `${hh}:`
  }
  const m = duration.minutes()
  const mm = m < 10 ? `0${m}` : `${m}`
  str += `${mm}:`
  const s = duration.seconds()
  const ss = s < 10 ? `0${s}` : `${s}`
  str += ss
  return str
}

const Loading = styled.span`
  display: block;
`
const Wave = styled.span`
  display: block;
  width: 100%;
  height: 90px;
  position: relative;
  > wave > wave {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: ${props => props.theme.primary};
      transform: scaleY(0);
      transition: transform 0.1s linear;
    }
  }
  > wave {
    ::-webkit-scrollbar {
      display: none;
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      display: none;
      background: #eee;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      display: none;
      background: #ccc;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      display: none;
      background: #666;
    }
  }
`
const Container = styled.span`
  -js-display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: transparent;
  &.loading {
    display: block;
    > * {
      visibility: hidden;
    }
    > ${Loading} {
      visibility: visible;
    }
  }
  &.playing {
    ${Wave} > wave {
      ::-webkit-scrollbar,
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb,
      ::-webkit-scrollbar-thumb:hover {
        display: block;
      }
      & > wave:after {
        transform: scaleY(1);
      }
    }
  }
  &:hover ${Wave} > wave {
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
      display: block;
    }
  }
`
const PlayPauseButton = styled(PlayPauseBtn)`
  margin-right: 1rem;
  flex-shrink: 0;
`
const Time = styled.span`
  margin-left: 1rem;
  white-space: nowrap;
`

const Audio: React.FC<Props> = (props: Props) => {
  const { src, alt = '', fallback = null } = props
  // const audioRef = React.useRef<HTMLAudioElement>(null)
  const ws = React.useRef<WaveSurfer>()
  const theme = React.useContext(ThemeContext)
  const ppBtnRef = React.useRef<HTMLButtonElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [playing, setPlaying] = React.useState(false)
  const [ready, setReady] = React.useState(false)
  const [currentTime, setCurrentTime] = React.useState('-')
  const [duration, setDuration] = React.useState('-')
  const intl = useIntl()
  const [, setErrorState] = React.useState()
  const supportedSrc = src.find(({ type }) => supportsType(type))

  // Initialize wavesurfer hook
  React.useEffect(() => {
    if (!supportedSrc) {
      return
    }
    // const audio = audioRef.current
    // if (!audio) {
    //   return
    // }
    const container = containerRef.current
    if (!container) {
      return
    }
    // console.log('Creating player', container)
    const player = WaveSurfer.create({
      barWidth: 3,
      barRadius: 3,
      barMinHeight: 2,
      cursorWidth: 1,
      container,
      backend: 'WebAudio',
      // backend: 'MediaElement',
      height: 90,
      progressColor: theme.primary,
      cursorColor: 'transparent',
      autoCenter: true,
      scrollParent: true,
      partialRender: true,
      responsive: true,
      waveColor: '#ccc',
      skipLength: 1,
      plugins: [
        CursorPlugin.create({
          showTime: true,
          hideOnBlur: true,
          opacity: 1,
          customShowTimeStyle: {
            backgroundColor: '#333',
            color: '#fff',
            padding: '5px 10px',
            fontSize: '1rem',
            borderRadius: '5px',
          },
        }),
      ],
    })
    // Set ref to the player
    ws.current = player
    // console.log('Audio', supportedSrc.src, supportedSrc.type)
    player.load(supportedSrc.src)
    player.on('play', () => {
      setPlaying(true)
      player.setCursorColor('rgba(0,0,0,.1)')
    })
    player.on('pause', () => {
      setPlaying(false)
      player.setCursorColor('transparent')
    })
    const updateTime = () => {
      const currentTime = moment.duration(player.getCurrentTime(), 'seconds')
      setCurrentTime(formatDuration(currentTime))
    }
    player.on('seek', () => {
      updateTime()
      // Show cursor on active seeking
      player.setCursorColor('rgba(0,0,0,.1)')
      const ppBtn = ppBtnRef.current
      if (ppBtn) {
        ppBtn.focus()
      }
    })
    player.on('ready', () => {
      const duration = moment.duration(player.getDuration(), 'seconds')
      setDuration(formatDuration(duration))
      setReady(true)
    })
    player.on('audioprocess', () => {
      updateTime()
    })
    player.on('error', err => {
      console.error('WaveSurfer error', err)
      setErrorState(() => {
        throw err
      })
    })
    return () => {
      // console.log('Destroying player')
      player.destroy()
    }
  }, [
    containerRef.current,
    ppBtnRef.current,
    theme,
    // src,
    supportedSrc?.src,
    // audioRef,
    setPlaying,
    setReady,
    setDuration,
    setCurrentTime,
  ])
  // Keyboard seeking hook
  React.useEffect(() => {
    const player = ws.current
    if (!player) {
      return
    }
    const listener = (event: KeyboardEvent) => {
      const isFocused =
        ppBtnRef.current && document.activeElement === ppBtnRef.current
      if (!player.isPlaying() && !isFocused) {
        return
      }
      if (event.key === 'ArrowLeft') {
        player.skipBackward()
      } else if (event.key === 'ArrowRight') {
        player.skipForward()
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [ws.current, ppBtnRef.current])

  if (!supportedSrc) {
    return <>{fallback}</>
  }

  const classNames: Array<string> = []
  if (ready) {
    classNames.push('ready')
  } else {
    classNames.push('loading')
  }
  if (playing) {
    classNames.push('playing')
  }

  return (
    <Container className={classNames.join(' ')} aria-label={alt}>
      {!ready && (
        <Loading>
          <CircleLoader as="span" />{' '}
          {intl.formatMessage({ id: 'audio.loadingaudio' })}
        </Loading>
      )}
      <PlayPauseButton
        onClick={() => {
          const player = ws.current
          if (player) {
            player.playPause()
          }
        }}
        state={playing ? 'playing' : 'paused'}
        tabIndex={0}
        aria-pressed={playing}
        ref={ppBtnRef}
        aria-label={
          playing
            ? intl.formatMessage({ id: 'audio.pause' })
            : intl.formatMessage({ id: 'audio.play' })
        }
      />
      <Wave ref={containerRef} />
      <Time>
        {currentTime} / {duration}
      </Time>
    </Container>
  )
}

export default Audio
